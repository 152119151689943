//import {BrowserRouter as Router, Route} from "react-router-dom";
import {BrowserRouter as Router, Route} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"
import Home from "./components/home.component";

//Admin
import useToken from './useToken';

function App() {  
  const token = useToken();
  return (
    <Router>
    
      <div className="container-fluid px-0">
      <Route path="/" exact component={Home}/>
      </div>
    </Router>
  );
}

export default App;
